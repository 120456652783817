// pages/index.tsx
import { FC } from "react";
import Image, { StaticImageData } from "next/image";
import { BorderWrapper, Container, FlexItem, FlexWrapper, Heading, ImageContainer, ImageWrapper, Paragraph, Section, TextWrapper } from "./AboutSectionTwo.styled";
interface AboutSectionProps {
  id: string;
  images: {
    src: StaticImageData;
    alt: string;
  }[];
  content: {
    heading: string;
    paragraph: string;
  }[];
}
const AboutSectionTwo: FC<AboutSectionProps> = ({
  id,
  images,
  content
}) => {
  return <Section id={id} data-sentry-element="Section" data-sentry-component="AboutSectionTwo" data-sentry-source-file="index.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <FlexWrapper data-sentry-element="FlexWrapper" data-sentry-source-file="index.tsx">
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <ImageWrapper data-sentry-element="ImageWrapper" data-sentry-source-file="index.tsx">
                            <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="index.tsx">
                                {images.map((image, index) => <Image key={index} src={image.src} alt={image.alt} height={555} width={925} />)}
                            </ImageContainer>
                        </ImageWrapper>
                    </FlexItem>
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <TextWrapper data-sentry-element="TextWrapper" data-sentry-source-file="index.tsx">
                            {content.map((item, index) => <div key={index}>
                                    <Heading>{item.heading}</Heading>
                                    <Paragraph>{item.paragraph}</Paragraph>
                                </div>)}
                        </TextWrapper>
                    </FlexItem>
                </FlexWrapper>
            </Container>
            <BorderWrapper data-sentry-element="BorderWrapper" data-sentry-source-file="index.tsx" />
        </Section>;
};
export default AboutSectionTwo;