// components/Common/SectionTitle.styled.ts
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import {
    SubTitle as StyledSubTitle,
    Text,
    Title as StyledTitle,
} from "utils/styling/typography.styled";

export const SectionTitleWrapper = styled.div<{
    $center?: boolean;
    width?: string;
    mb?: string;
}>`
    width: 100%;
    max-width: ${(props) => props.width || "35.625rem"};
    margin-bottom: ${(props) => props.mb || "6.25rem"};
    ${(props) =>
        props.$center &&
        "margin-left: auto; margin-right: auto; text-align: center;"}
    animation: fadeInUp 0.1s ease-in-out;

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 2.5rem, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`;

export const SubTitle = styled(StyledSubTitle)`
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;

    ${device.md} {
        font-size: 2.25rem;
    }

    ${device.lg} {
        font-size: 2.8125rem;
    }
`;

export const Title = styled(StyledTitle)`
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;

    ${device.md} {
        font-size: 2.25rem;
    }

    ${device.lg} {
        font-size: 2.8125rem;
    }
`;

export const Paragraph = styled(Text)`
    font-size: 1rem;
    line-height: 1.75;
    color: ${colourScheme.grey};
    text-align: justify;
    text-justify: inter-word;

    ${device.md} {
        font-size: 1.125rem;
    }

    ${device.lg} {
        font-size: 1.25rem;
    }
`;
