import AboutLeaderboard from "./about-leaderboard.webp";
import AboutLeaderboardFull from "./about-leaderboard-full.webp";
import AboutSave from "./about-save.webp";
import AboutShare from "./about-share.webp";
import AboutShareSocial from "./about-share-social.webp";
import FeatureThemes from "./feature-themes.webp";
import FeaturesEasyArtGeneration from "./features-easy-art-generation.webp";
import FeaturesEasyToUse from "./features-easy-to-use.webp";
import FeaturesGallery from "./features-gallery.webp";
import FeaturesModelControl from "./features-model-control.webp";
import FeaturesMonsterGenerator from "./features-monster-generator.webp";
import FeaturesStatBlock from "./features-statblock.webp";
import HeroFeaturesImage from "./hero-features-image.webp";
import PremiumVsFreeComparison from "./premium-vs-free-comparison.webp";
import TestimonialBuclear from "./testimonial-buclear.webp";
import TestimonialKrackle from "./testimonial-krackle.webp";
import TestimonialLogan from "./testimonial-logan.webp";

export {
    AboutLeaderboard,
    AboutLeaderboardFull,
    AboutSave,
    AboutShare,
    AboutShareSocial,
    FeaturesEasyArtGeneration,
    FeaturesEasyToUse,
    FeaturesGallery,
    FeaturesModelControl,
    FeaturesMonsterGenerator,
    FeaturesStatBlock,
    FeatureThemes,
    HeroFeaturesImage,
    PremiumVsFreeComparison,
    TestimonialBuclear,
    TestimonialKrackle,
    TestimonialLogan,
};
