// components/Pricing/PricingBox.tsx
import { FC } from "react";
import { Button } from "components/atoms/Button";
import { ButtonType } from "components/atoms/Button/types";
import { Amount, PackageName, Price, PricingButtonWrapper, PricingCard, PricingContainer, PricingHeader, PricingSubtitle, Time } from "./PricingBox.styled";
interface PricingBoxProps {
  price: string;
  duration?: string;
  packageName: string;
  subtitle: string;
  children: React.ReactNode;
  buttonText: string;
  handleButtonClick: () => void;
}
const PricingBox: FC<PricingBoxProps> = ({
  price,
  duration,
  packageName,
  subtitle,
  children,
  buttonText,
  handleButtonClick
}) => {
  return <PricingContainer data-sentry-element="PricingContainer" data-sentry-component="PricingBox" data-sentry-source-file="index.tsx">
            <PricingCard data-sentry-element="PricingCard" data-sentry-source-file="index.tsx">
                <PricingHeader data-sentry-element="PricingHeader" data-sentry-source-file="index.tsx">
                    <Price data-sentry-element="Price" data-sentry-source-file="index.tsx">
                        £<Amount data-sentry-element="Amount" data-sentry-source-file="index.tsx">{price}</Amount>
                        {duration && <Time>/{duration} (ex. tax)</Time>}
                    </Price>
                    <PackageName data-sentry-element="PackageName" data-sentry-source-file="index.tsx">{packageName}</PackageName>
                </PricingHeader>
                <PricingSubtitle data-sentry-element="PricingSubtitle" data-sentry-source-file="index.tsx">{subtitle}</PricingSubtitle>
                <PricingButtonWrapper data-sentry-element="PricingButtonWrapper" data-sentry-source-file="index.tsx">
                    <Button variant={ButtonType.SUCCESS} onClick={handleButtonClick} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        {buttonText}
                    </Button>
                </PricingButtonWrapper>
                <div>{children}</div>
            </PricingCard>
        </PricingContainer>;
};
export default PricingBox;