// components/Pricing/index.tsx
"use client";

import { useContext, useState } from "react";
import SectionTitle from "components/atoms/SectionTitle";
import OfferList from "components/molecules/OfferList";
import PricingBox from "components/molecules/PricingBox";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
import { Container, PricingGrid, PricingToggleWrapper, Section } from "./Pricing.styled";
const Pricing = ({
  isHeading
}: {
  isHeading?: boolean;
}) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  const handleSubscribeClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  };
  return <Section id="pricing" data-sentry-element="Section" data-sentry-component="Pricing" data-sentry-source-file="index.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <SectionTitle isHeading={isHeading} title="Simple and Affordable Pricing" paragraph="Choose the plan that best fits your needs and start creating today. Whether you're just getting started or looking to take your fantasy art to the next level, CharGen has a plan for you." center data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                <PricingToggleWrapper data-sentry-element="PricingToggleWrapper" data-sentry-source-file="index.tsx">
                    <span onClick={() => setIsMonthly(true)} className={isMonthly ? "active" : "inactive"}>
                        Monthly
                    </span>
                    <div className="toggle" onClick={() => setIsMonthly(!isMonthly)}>
                        <div className="toggle-inner">
                            <div className={`toggle-switch ${isMonthly ? "" : "active"}`}></div>
                        </div>
                    </div>
                    <span onClick={() => setIsMonthly(false)} className={isMonthly ? "inactive" : "active"}>
                        Yearly
                    </span>
                </PricingToggleWrapper>
                <PricingGrid data-sentry-element="PricingGrid" data-sentry-source-file="index.tsx">
                    <PricingBox packageName="Basic" price={isMonthly ? "3.99" : "39.99"} duration={isMonthly ? "mo" : "yr"} subtitle="Unlock your creative potential with CharGen Basic! Enjoy 300 Gold monthly to generate stunning images, access all features, and explore our diverse models. Perfect for beginners, get started with unlimited possibilities." buttonText="Subscribe Now" handleButtonClick={handleSubscribeClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="Monthly Gold: Receive 300 Gold every month (300 SDXL Images)" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access all features and generate as many images as your Gold allows." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access to our premium models." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Save and load presets for characters, NPCs, and monsters quickly and easily." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Model Control: Advanced settings like steps, CFG, and samplers" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Image Editing: Utilize advanced edit image functionality" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Priority Support: Get faster responses and dedicated support for all your needs." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="Plus" price={isMonthly ? "9.99" : "109.99"} duration={isMonthly ? "mo" : "yr"} subtitle="Double your creativity with CharGen Plus! Receive 900 Gold monthly, enabling you to create high-quality images with exclusive models and features. Ideal for hobbyists, enjoy a superior artistic experience." buttonText="Subscribe Now" handleButtonClick={handleSubscribeClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="Monthly Gold: Receive 900 Gold every month (900 SDXL Images)" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access all features and generate as many images as your Gold allows." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access to our premium models." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Save and load presets for characters, NPCs, and monsters quickly and easily." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Model Control: Advanced settings like steps, CFG, and samplers" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Image Editing: Utilize advanced edit image functionality" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Priority Support: Get faster responses and dedicated support for all your needs." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="Elite" price={isMonthly ? "19.99" : "219.99"} duration={isMonthly ? "mo" : "yr"} subtitle="Supercharge with CharGen Elite! Enjoy 2000 Gold monthly for creating exquisite images, with priority access to premium models and advanced settings. Perfect for dedicated creators seeking enhanced control." buttonText="Subscribe Now" handleButtonClick={handleSubscribeClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="Monthly Gold: Receive 2000 Gold every month (2000 SDXL Images)" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access all features and generate as many images as your Gold allows." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access to our premium models." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Save and load presets for characters, NPCs, and monsters quickly and easily." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Model Control: Advanced settings like steps, CFG, and samplers" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Image Editing: Utilize advanced edit image functionality" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Priority Support: Get faster responses and dedicated support for all your needs." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="Ultimate" price={isMonthly ? "39.99" : "439.99"} duration={isMonthly ? "mo" : "yr"} subtitle="Become better than the best with CharGen Ultimate! Get 4500 Gold monthly to create top-tier images with exclusive access to the best models, advanced features, and priority support. Perfect for professional artists and enthusiasts." buttonText="Subscribe Now" handleButtonClick={handleSubscribeClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="Monthly Gold: Receive 4500 Gold every month (4500 SDXL Images)" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access all features and generate as many images as your Gold allows." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Access to our premium models." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Save and load presets for characters, NPCs, and monsters quickly and easily." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Model Control: Advanced settings like steps, CFG, and samplers" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Enhanced Image Editing: Utilize advanced edit image functionality" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="Priority Support: Get faster responses and dedicated support for all your needs." data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                </PricingGrid>
            </Container>
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <SectionTitle title="Unlock Premium Art Generation with Gold" paragraph={["Perfect for those who prefer flexibility, Gold can be a one-off purchase, giving you the freedom to enhance your art without a subscription.", "For ongoing benefits, consider our Subscription packages, which include varying increments of gold per month, at a cheaper cost than one-off purchases."]} center data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                <PricingGrid data-sentry-element="PricingGrid" data-sentry-source-file="index.tsx">
                    <PricingBox packageName="300 Gold" price="5.99" subtitle="Kickstart your creativity with the Starter Adventure Pack! Get 300 Gold to explore all models and features. Perfect for newcomers wanting to try premium features. 300 Gold gets you 300 high quality images." buttonText="Buy Now" handleButtonClick={handlePricingClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="300 Quality Model Images" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="30 Premium Generations" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="ADetailer + Face Fixing" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="900 Gold" price="14.99" subtitle="Enhance your artistic journey with the Trove! Gain 900 Gold for creating more advanced images and exploring premium models." buttonText="Buy Now" handleButtonClick={handlePricingClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="900 Quality Model Images" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="90 Premium Generations" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="ADetailer + Face Fixing" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="2000 Gold" price="29.99" subtitle="Elevate your creations with the Treasury! Receive 2000 Gold to unlock premium features, advanced models, and more.." buttonText="Buy Now" handleButtonClick={handlePricingClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="2000 Quality Model Images" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="200 Premium Generations" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="ADetailer + Face Fixing" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                    <PricingBox packageName="4500 Gold" price="59.99" subtitle="Unleash your full potential with the Dragon Hoard! Get 4500 Gold for unlimited creativity and access to the most exclusive features and models." buttonText="Buy Now" handleButtonClick={handlePricingClick} data-sentry-element="PricingBox" data-sentry-source-file="index.tsx">
                        <OfferList text="4500 Quality Model Images" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="450 Premium Generations" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                        <OfferList text="ADetailer + Face Fixing" data-sentry-element="OfferList" data-sentry-source-file="index.tsx" />
                    </PricingBox>
                </PricingGrid>
            </Container>
        </Section>;
};
export default Pricing;