// components/Common/SectionTitle.tsx
import { Paragraph, SectionTitleWrapper, SubTitle, Title } from "./SectionTitle.styled";
const SectionTitle = ({
  title,
  paragraph,
  width,
  center,
  mb,
  isHeading
}: {
  title: string;
  paragraph: string | string[];
  width?: string;
  center?: boolean;
  mb?: string;
  isHeading?: boolean;
}) => {
  return <SectionTitleWrapper $center={center} width={width} mb={mb} data-sentry-element="SectionTitleWrapper" data-sentry-component="SectionTitle" data-sentry-source-file="index.tsx">
            {isHeading ? <Title>{title}</Title> : <SubTitle>{title}</SubTitle>}
            {Array.isArray(paragraph) ? paragraph.map((line, index) => <div key={index}>
                        <Paragraph>{line}</Paragraph>
                        <br />
                    </div>) : <Paragraph>{paragraph}</Paragraph>}{" "}
        </SectionTitleWrapper>;
};
export default SectionTitle;