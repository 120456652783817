import { AboutSave, AboutShare } from "images";

export const aboutSectionSaveContent = [
    {
        heading: "Save Your Character Options",
        paragraph:
            "With our save feature, you can quickly and easily save all your character options, including detailed descriptions, appearances, abilities, and even links to PDFs and URLs. This allows you to revisit and regenerate your D&D characters, NPCs, and monsters with just a few clicks, streamlining your creative process and saving you valuable time. Use our D&D character creator and AI RPG generator to bring your ideas to life effortlessly.",
    },
    {
        heading: "Get Back to Creating Faster",
        paragraph:
            "Say goodbye to repetitive data entry! Our save feature ensures that all your preferences are stored and ready for you whenever you need them. By saving your character options, you can focus more on your creativity and less on configuration. Whether you’re preparing for a Dungeons and Dragons campaign or crafting a single masterpiece, our system helps you get back to generating stunning digital art in no time using our best AI image generator.",
    },
    {
        heading: "Exclusive to Subscribers",
        paragraph:
            "Unlock the full potential of our save feature by becoming a Subscriber. This premium benefit allows you to store an unlimited number of presets, ensuring you always have access to your favorite D&D characters, NPCs, and monsters. Elevate your creative experience with our D&D character maker and AI generated art tools, designed exclusively for our most dedicated users.",
    },
];

export const aboutSectionSaveImages = [
    {
        src: AboutSave,
        alt: "User interface showcasing an easy way to save AI-generated characters for quick access using our D&D character creator.",
    },
];

export const aboutSectionShareImages = [
    {
        src: AboutShare,
        alt: "User interface showcasing an easy way to share AI-generated characters with the community using our AI character creator.",
    },
    // {
    //     src: AboutShareSocial,
    //     alt: "User interface demonstrating a simple method to share AI-generated NPCs (non-player characters) with others.",
    // },
];

export const aboutSectionShareContent = [
    {
        heading: "Easily Share Your CharGens",
        paragraph:
            "Use our image sharing page to present your CharGen creations to the community. Share not only the images but also detailed descriptions, appearances, and backgrounds of your monsters, NPCs, and characters created with our AI character creator and D&D character maker. Engage with other users, exchange feedback, and showcase your creative work to inspire and be inspired by fellow enthusiasts.",
    },
    {
        heading: "Share on Social Media for Rewards",
        paragraph:
            "Share your CharGen creations on social media, tag us, and earn free gold! Whether its an image or a full character description, each share gets you closer to unlocking exclusive themes, advanced features, and more customization options. Start sharing and start earning today!",
    },
];
