// components/Pricing/OfferList.tsx
import { FC } from "react";
import Image from "next/image";
import { CheckIcon } from "components/atoms/Icon";
import { IconWrapper, OfferListContainer, OfferText } from "./OfferList.styled";
interface OfferListProps {
  text: React.ReactNode;
}
const OfferList: FC<OfferListProps> = ({
  text
}) => <OfferListContainer data-sentry-element="OfferListContainer" data-sentry-component="OfferList" data-sentry-source-file="index.tsx">
        <IconWrapper data-sentry-element="IconWrapper" data-sentry-source-file="index.tsx">
            <Image height={18} width={18} src={CheckIcon} alt="Check Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </IconWrapper>
        <OfferText data-sentry-element="OfferText" data-sentry-source-file="index.tsx">{text}</OfferText>
    </OfferListContainer>;
export default OfferList;