// pages/index.tsx
import Image from "next/image";
import SectionTitle from "components/atoms/SectionTitle";
import { PremiumVsFreeComparison } from "images";
import { BorderWrapper, Container, FlexItem, FlexWrapper, ImageWrapper, Section } from "./AboutSection.styled";
const AboutSectionOne = () => {
  return <Section id="about" data-sentry-element="Section" data-sentry-component="AboutSectionOne" data-sentry-source-file="index.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <FlexWrapper data-sentry-element="FlexWrapper" data-sentry-source-file="index.tsx">
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <SectionTitle title="Access All Image Generator Features with Gold" paragraph={["Gold provides you with a flexible budget to enhance your art generation experience. Use gold to access better models such as Flux.Pro, apply face fixing, utilize Adetailer, generate more images, and choose different sizes.\n", "Go Premium with your gold and enjoy higher quality renders and better race generation, bringing your characters to life with greater detail and accuracy.\n", "Access exclusive premium themes and create stunning, high-definition images that stand out. Earn gold for free without paying, enabling you to experience all the premium features CharGen offers, from AI character creation to generating the best D&D character art and AI-generated images."]} data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                    </FlexItem>
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <ImageWrapper data-sentry-element="ImageWrapper" data-sentry-source-file="index.tsx">
                            <Image src={PremiumVsFreeComparison} alt="Side-by-side comparison of free and premium generated characters, showcasing the superior quality of premium generation." height={512} width={1024} style={{
              width: "100%",
              height: "auto"
            }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        </ImageWrapper>
                    </FlexItem>
                </FlexWrapper>
            </Container>
            <BorderWrapper data-sentry-element="BorderWrapper" data-sentry-source-file="index.tsx" />
        </Section>;
};
export default AboutSectionOne;