// components/About/AboutSectionOne.styled.ts
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Text } from "utils/styling/typography.styled";

export const Section = styled.section`
    max-width: 100vw;
    width: 100%;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 80vw;
    padding: 0 1rem;
    margin: 0 auto;
    padding-top: 4rem;

    ${device.md} {
        padding-top: 5rem;
    }

    ${device.lg} {
        padding-top: 7rem;
    }
`;

export const BorderWrapper = styled.div`
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    ${device.md} {
        padding-bottom: 5rem;
    }

    ${device.lg} {
        padding-bottom: 7rem;
    }
`;

export const FlexWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin: 0 -1rem;
    flex-direction: row;
    justify-content: space-between;
`;

export const FlexItem = styled.div`
    width: 100%;
    padding: 0 1rem;

    ${device.md} {
        width: 50%;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    ${device.md} {
        width: 100%;
    }
`;

export const Heading = styled.h3`
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    font-family: inherit;
    ${device.md} {
        font-size: 1.5rem;
    }

    ${device.lg} {
        font-size: 1.75rem;
    }
`;

export const Paragraph = styled(Text)`
    font-size: 1rem;
    font-weight: medium;
    color: ${colourScheme.grey};
    text-align: justify;
    text-justify: inter-word;

    ${device.md} {
        font-size: 1.125rem;
    }

    ${device.lg} {
        font-size: 1.25rem;
    }
`;

export const TextWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    ${device.lg} {
        margin: 0;
    }
`;
