// components/Pricing/PricingBox.styled.ts
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Span } from "utils/styling/typography.styled";

export const PricingContainer = styled.div`
    width: 100%;
    max-width: 80vw;

    ${device.md} {
        max-width: 40vw;
    }
    ${device.lg} {
        max-width: 20vw;
    }
`;

export const PricingCard = styled.div`
    background-color: ${colourScheme.primaryLight};
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 20px rgba(75, 78, 110, 0.4);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 10px 40px rgba(75, 78, 110, 0.8);
    }

    ${device.md} {
        padding: 2.5rem;
    }

    ${device.lg} {
        padding: 3rem;
    }
`;

export const PricingHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const Price = styled.h3`
    font-size: 1.875rem;
    font-weight: 700;
    color: ${colourScheme.white};
`;

export const Amount = styled(Span)`
    font-size: 2.5rem;
`;

export const Time = styled(Span)`
    color: ${colourScheme.grey};
`;

export const PackageName = styled.h4`
    font-size: 1.25rem;
    font-weight: 700;
    color: ${colourScheme.white};
`;

export const PricingSubtitle = styled.p`
    margin-bottom: 1.75rem;
    font-size: 1rem;
    color: ${colourScheme.grey};
`;

export const PricingButtonWrapper = styled.div`
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 2rem;
`;
