// components/Pricing/Pricing.styled.ts
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const Section = styled.section`
    position: relative;
    z-index: 10;
    padding: 4rem 0;

    ${device.md} {
        padding: 5rem 0;
    }

    ${device.lg} {
        padding: 7rem 0;
    }
`;

export const Container = styled.div`
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 0 1rem;

    ${device.md} {
        margin-bottom: 4rem;
    }

    ${device.lg} {
        margin-bottom: 6rem;
    }
`;

export const PricingToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    span {
        margin: 0 1rem;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;

        &.active {
            color: ${colourScheme.success};
        }

        &.inactive {
            color: ${colourScheme.grey};
        }
    }

    .toggle {
        display: flex;
        align-items: center;

        .toggle-inner {
            position: relative;
            width: 2rem;
            height: 1rem;
            background-color: ${colourScheme.primaryDark};
            border-radius: 1rem;
            margin: 0 0.5rem;
            cursor: pointer;

            .toggle-switch {
                position: absolute;
                top: -0.125rem;
                left: 0;
                width: 1.25rem;
                height: 1.25rem;
                background-color: ${colourScheme.success};
                border-radius: 50%;
                transition: transform 0.3s ease-in-out;

                &.active {
                    transform: translateX(100%);
                }
            }
        }
    }
`;

export const PricingGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    width: 100%;

    @media ${device.md} {
        gap: 2.5rem;
    }

    @media ${device.lg} {
        gap: 3rem;
    }
`;

export const SaleBadge = styled.div`
    background-color: ${colourScheme.primaryDark};
    color: ${colourScheme.success};
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    justify-content: center;
    display: flex;
    z-index: 1;
`;
