// components/Pricing/OfferList.styled.ts
import styled from "styled-components";

import { colourScheme } from "utils/styling/colours";
import { Text } from "utils/styling/typography.styled";

export const OfferListContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.75rem;
    background-color: ${colourScheme.primary}1A; // Adjusted for 10% opacity
    color: ${colourScheme.primary};
    border-radius: 50%;
`;

export const OfferText = styled(Text)`
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
`;
